import React from "react";
import { makeStyles } from "@material-ui/core";
import { List, ListItem } from "@material-ui/core";
//import { Link } from "react-router-dom";

import Button from "../../components/Buttons/Button.js";

import styles from "assets/jss/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks() {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="/projects"
          color="transparent"
          className={classes.navLink}
        >
          Projects
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/#services"
          color="transparent"
          className={classes.navLink}
        >
          Services
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/contact-us"
          color="transparent"
          className={classes.navLink}
        >
          Contact Us
        </Button>
      </ListItem>
    </List>
  );
}
/*
for SPA if needed
          component={Link}
          to="/projects"
          
*/
