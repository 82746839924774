import React, { Fragment } from "react";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import BottomSection from "components/BottomSection/BottomSection.js";
import PropTypes from "prop-types";
export default function Layout(props) {
  const { children } = props;
  return (
    <Fragment>
      <Header
        brand="Solutions General"
        href="/"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      {children}
      <BottomSection />
    </Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
