const API_URL = process.env.REACT_APP_API_URL;

export class API {
  static async getClients() {
    return await fetch(`${API_URL}/clients/`).then((response) =>
      response.json()
    );
  }
  static async getProjects() {
    return await fetch(`${API_URL}/projects/`).then((response) =>
      response.json()
    );
  }
  static async getLicenses() {
    return await fetch(`${API_URL}/licenses/`).then((response) =>
      response.json()
    );
  }
  static async sendMessage(message) {
    return await fetch(`${API_URL}/send-message/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": window.csrf_token,
        "X-Requested-With": "XMLHttpRequest",
      },
      body: JSON.stringify(message),
    })
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
}

/*export class API {
    static async updateMovie(movie_id, body, token) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/movies/${movie_id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify(body),
        }
      );
      return await response.json();
    }
    static async createMovie(body, token) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/movies/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify(body),
        }
      );
      return await response.json();
    }
    static async deleteMovie(id, token) {
      await fetch(`${process.env.REACT_APP_API_URL}/api/movies/${id}/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
    }
    static async loginUser(body) {
      return fetch(`${process.env.REACT_APP_API_URL}/auth/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((response) => response.json());
    }
    static async registerUser(body) {
      return fetch(`${process.env.REACT_APP_API_URL}/api/users/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }).then((response) => response.json());
    }
    static async getMovies(token) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/movies/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );
        return await response.json();
      } catch (error) {
        return console.log(error);
      }
    }
  }
  */
