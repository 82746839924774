import { cardTitle, title } from "assets/jss/Base.js";
import imagesStyle from "assets/jss/imageStyles.js";

const projectsSectionStyle = {
  section: {
    padding: "50px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  ...imagesStyle,
  itemGrid: {
    //padding: "10px",
    //alignItems: "center",
    margin: "auto",
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d",
  },
  description: {
    color: "#999",
  },
  justifyCenter: {
    justifyContent: "center !important",
  },
  margin5: {
    margin: "5px",
  },
  cardBodyText: {
    textAlign: "left",
  },
  "@media (max-width: 576px)": {
    staggered: {
      "& >div:nth-of-type(odd)": {
        "& >div": {
          "& >div": {
            flexDirection: "row-reverse",
          },
        },
      },
      "& >div:nth-of-type(even)": {
        "& >div": {
          "& >div": {},
        },
      },
    },
  },
  "@media (min-width: 576px)": {
    staggered: {
      "& >div:nth-of-type(odd)": {
        "& >div": {
          width: "80%",
          float: "left",
          //backgroundColor: "#00ff00",
          "& >div": {
            flexDirection: "row-reverse",
          },
        },
      },
      "& >div:nth-of-type(even)": {
        "& >div": {
          width: "80%",
          float: "right",
          //backgroundColor: "#ff0000",
          "& >div": {},
        },
      },
    },
  },

  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
};

export default projectsSectionStyle;
