import React, { useRef, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";
import "./MapBox.css";

const styles = {
  mapContainer: {
    position: "absolute",
    top: "0px",
    right: "0px",
    left: "0px",
    bottom: "0px",
  },
  sidebarStyle: {
    display: "inline-block",
    position: "absolute",
    top: "0px",
    left: "0px",
    margin: "12px",
    backgroundColor: "#404040",
    color: "#ffffff",
    zIndex: "1 !important",
    padding: "6px",
    fontWeight: "bold",
  },
};

mapboxgl.accessToken =
  "pk.eyJ1IjoiampraXZhaSIsImEiOiJja2lwa3p3eTAwN3Y0MnNwOXFqbWVlbGpjIn0.RMy9HOOXHvB-QmspYCOMAA";
/*class MapBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: -1.36,
      lgn: 36.92,
      zoom: 15,
    };
  }
  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [this.state.lgn, this.state.lat],
      zoom: this.state.zoom,
    });
    map.on("move", () => {
      this.setState({
        lgn: map.getCenter().lng.toFixed(2),
        lat: map.getCenter().lat.toFixed(2),
        zoom: map.getZoom().toFixed(1),
      });
    });
    const marker = new mapboxgl.Marker().setLngLat([36.92, -1.36]).addTo(map);
  }
  render() {
    const { classes } = this.props;
    return (
      <div style={{ minHeight: 200 }}>
        <div className={classes.sidebarStyle}>
          <div>
            Longitude: {this.state.lgn} | Latitude: {this.state.lat} | Zoom:{" "}
            {this.state.zoom}
          </div>
        </div>
        <div
          ref={(el) => (this.mapContainer = el)}
          className={classes.mapContainer}
        />
      </div>
    );
  }
}*/
const MapBox = (props) => {
  const { classes } = props;
  const mapContainerRef = useRef(null);
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/outdoors-v11",
      center: [36.92008409742362, -1.362563453966777],
      zoom: 15,
    });
    map.on("load", function () {
      map.loadImage(
        "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
        // Add an image to use as a custom marker
        function (error, image) {
          if (error) throw error;
          map.addImage("custom-marker", image);

          map.addSource("places", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {
                    description:
                      "<strong>Solutions General Ltd</strong><p>Fortis Industrial Park, #17</p>",
                  },
                  geometry: {
                    type: "Point",
                    coordinates: [36.92008409742362, -1.362563453966777],
                  },
                },
              ],
            },
          });

          // Add a layer showing the places.
          map.addLayer({
            id: "places",
            type: "symbol",
            source: "places",
            layout: {
              "icon-image": "custom-marker",
              "icon-allow-overlap": true,
            },
          });
        }
      );

      // Create a popup, but don't add it to the map yet.
      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      map.on("mouseenter", "places", function (e) {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = "pointer";

        var coordinates = e.features[0].geometry.coordinates.slice();
        var description = e.features[0].properties.description;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });

      map.on("mouseleave", "places", function () {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });
    });

    return () => map.remove();
  }, []);
  return (
    <div>
      <div ref={mapContainerRef} className={classes.mapContainer} />
    </div>
  );
};
MapBox.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MapBox);
/*new mapboxgl.Marker()
      .setLngLat([36.92, -1.36])
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }).setHTML(
          "<h4> Fortis Industrial Park </h4>"
        )
      )
      .addTo(map);*/
