import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core";

import Button from "../Buttons/Button.js";

import styles from "assets/jss/views/ViewSections/modalSectionStyles.js";

const useStyles = makeStyles(styles);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function Modal(props) {
  const classes = useStyles();
  const { isOpen, closeHandle, title, children } = props;
  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => closeHandle(-1)}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => closeHandle(-1)}
        >
          <Close className={classes.modalClose} />
        </IconButton>
        <h4 className={classes.modalTitle}>{title}</h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        {children}
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={() => closeHandle(-1)} color="danger" simple>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
Modal.defaultProps = {
  title: "",
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeHandle: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};
