import React, { useState, useEffect } from "react";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

import styles from "../../assets/jss/views/viewStyles.js";

import Documents from "assets/img/Documents.jpg";
import Layout from "components/Layout.js";
import { API } from "assets/js/API.js";
/*const images = [
  "http://fpoimg.com/500x300?text=Preview&bg_color=00001e",
  "http://fpoimg.com/500x250?text=Preview&bg_color=eb001e",
  "http://fpoimg.com/500x300?text=Preview&bg_color=ebff1e",
  "http://fpoimg.com/500x330?text=Preview&bg_color=eb00ff",
  "http://fpoimg.com/500x630?text=Preview&bg_color=eb00ff",
];*/

const useStyles = makeStyles(styles);

export default function Licenses() {
  const classes = useStyles();
  const [licenses, setLicenses] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    API.getLicenses().then((data) => {
      setLicenses(data);
      setLoading(false);
    });
  }, []);

  document.title = "Licenses";
  return (
    <Layout>
      <Parallax filter image={Documents}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Licenses</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {loading ? (
            <div className={classes.section}>
              <h2 className={classes.title}>Projects</h2>
              <div>
                <GridContainer>
                  <GridItem
                    xs={12}
                    md={12}
                    style={{
                      height: "100vh",
                      marginLeft: "50%",
                      marginRight: "50%",
                    }}
                  >
                    <CircularProgress color="primary" />
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          ) : (
            <div style={{ padding: "50px 0", textAlign: "center" }}>
              <GridContainer>
                {licenses.map(({ id, license_image, name }) => (
                  <GridItem
                    key={id}
                    xs={12}
                    style={{ padding: "10px", maxWidth: "inherit" }}
                  >
                    <img
                      src={license_image}
                      alt={name}
                      style={{ width: "100%" }}
                    />
                  </GridItem>
                ))}
              </GridContainer>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
