/* eslint-disable  */
import React from "react";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

import styles from "../../assets/jss/views/viewStyles.js";

import LightsBackground from "assets/img/LightsBackground.jpg";
import Layout from "components/Layout.js";

import ProjectsSection from "./Sections/ProjectsSection.js";

const useStyles = makeStyles(styles);

export default function Projects(props) {
  const classes = useStyles();
  document.title = "Projects";
  return (
    <Layout>
      <Parallax filter small image={LightsBackground}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Projects</h1>
                <h3 className={classes.subtitle}>
                  Delivering quality service that is guaranteed to reach the
                  expectations of the customer
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProjectsSection />
        </div>
      </div>
    </Layout>
  );
}
