import { container, title } from "assets/jss/Base.js";

const bottomStyle = {
  container,
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
};
export default bottomStyle;
