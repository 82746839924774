import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { FlashOn, Visibility, Dns, Eco } from "@material-ui/icons";
import OutlineButton from "@material-ui/core/Button";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "../../../components/Buttons/Button.js";

import styles from "assets/jss/views/ViewSections/whatWeDoSectionStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>What we do</h2>
          <h5 className={classes.description}>
            This is the paragraph where you can write more details about your
            product. Keep you user engaged by providing meaningful information.
            Remember that by this time, the user is curious, otherwise he wouldn
            {"'"}t scroll to get here. Add a button if you want the user to see
            more.
          </h5>
          <OutlineButton variant="outlined" color="primary" href="/about-us">
            ABOUT US
          </OutlineButton>
        </GridItem>
      </GridContainer>
      <div id="services">
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.title}>What we offer</h2>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Power Distribution"
              description="We design, build and install power boards ranging from
                    simple supply to automated changeover units."
              icon={FlashOn}
              iconColor="warning"
              vertical
            />
            <Button color="primary" simple>
              READ MORE
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Security And Surveillance"
              description="Installation of state of the art monitoring and control
                    systems ensuring top-notch security."
              icon={Visibility}
              iconColor="gray"
              vertical
            />
            <Button color="primary" simple>
              READ MORE
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Servers And Networks"
              description="Specialists in building and maintaing servers and network
                    systems that will guarantee speed and efficiency."
              icon={Dns}
              iconColor="danger"
              vertical
            />
            <Button color="primary" simple>
              READ MORE
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <InfoArea
              title="Green Energy"
              description="We install and commission photovoltaic panels (solar) as we
                    move to reduce carbon emmisions."
              icon={Eco}
              iconColor="success"
              vertical
            />
            <Button color="primary" simple>
              READ MORE
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
