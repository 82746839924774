import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core";

export default function Image(props) {
  const { image, style, className, alt } = props;
  return (
    <div style={style} className={className}>
      <img src={image} alt={alt} />
    </div>
  );
}
Image.propTypes = {
  image: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  alt: PropTypes.string,
};
