import React from "react";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import PinDropIcon from "@material-ui/icons/PinDrop";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

import styles from "../../assets/jss/views/viewStyles.js";

import ContactUsPhoto from "assets/img/ContactUsPhoto.jpg";
import Layout from "components/Layout.js";

const useStyles = makeStyles(styles);

export default function ContactUs() {
  const classes = useStyles();
  document.title = "Contact Us";
  return (
    <Layout>
      <Parallax filter small image={ContactUsPhoto}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Contact Us</h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div style={{ padding: "50px 0", textAlign: "center" }}>
            <h2
              style={{
                color: "#3C4858",
                margin: "1.75rem 0 0.875rem",
                textDecoration: "none",
                fontWeight: "700",
                fontFamily: `"Roboto Slab", "Times New Roman", serif`,
                marginBottom: "1rem",
                marginTop: "30px",
                minHeight: "32px",
              }}
            >
              Our Contact Info
            </h2>
            <h5
              style={{
                padding: "10px",
                marginBottom: "1rem",
                margin: "1.75rem 0 0.875rem",
              }}
            >
              Feel free to reach out to us with one of the following contact
              methods listed, or send us a message below
            </h5>
            <GridContainer>
              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={12} md={4}>
                    <GridItem xs={12}>
                      <PhoneIcon fontSize={"large"} />
                    </GridItem>
                    <GridItem xs={12}>
                      <p>
                        <strong>Give us a ring</strong>
                      </p>
                    </GridItem>
                    <GridItem xs={12}>
                      <p>Patrick Nduva</p>
                    </GridItem>
                    <GridItem xs={12}>
                      <p>Mon-Fri, 8:00 AM - 5:00 PM</p>
                    </GridItem>
                    <GridItem xs={12}>
                      <p>
                        <a href="tel:+254722763156">+254722763156</a>
                      </p>
                    </GridItem>
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <GridItem xs={12}>
                      <EmailIcon fontSize={"large"} />
                    </GridItem>
                    <GridItem xs={12}>
                      <p>
                        <strong>Send us an Email</strong>
                      </p>
                    </GridItem>
                    <GridItem xs={12}>
                      <span>
                        <a href="mailto:info@solutionsgen.co.ke">
                          info@solutionsgen.co.ke
                        </a>
                      </span>
                    </GridItem>
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <GridItem xs={12}>
                      <span>
                        <PinDropIcon fontSize={"large"} />
                      </span>
                    </GridItem>
                    <GridItem xs={12}>
                      <p>
                        <strong>Where we are</strong>
                      </p>
                      <p>
                        Number 17,
                        <br />
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.google.com/maps/place/Fortis+Industrial+Park/@-1.3628316,36.9178954,17z/data=!3m1!4b1!4m5!3m4!1s0x182f0d07de0f5ddf:0xef79534810b17a1d!8m2!3d-1.3628316!4d36.9200841"
                        >
                          Fortis Industrial Park
                        </a>
                        <br />
                        Nairobi, Kenya
                      </p>
                    </GridItem>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
}
