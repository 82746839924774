import { container } from "assets/jss/Base.js";
//renamed from material-kit-react.js

const componentsStyle = {
  container,
  brand: {
    color: "#FFFFFF",
    textAlign: "left",
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  "@media (max-width: 576px)": {
    maxWidth: "540px",
    mainRaised: {
      margin: "-60px 0px 0px",
    },
  },
  "@media (min-width: 576px)": {
    maxWidth: "540px",
    mainRaised: {
      margin: "-60px 30px 0px",
    },
  },
  "@media (min-width: 768px)": {
    maxWidth: "720px",
    mainRaised: {
      margin: "-60px 30px 0px",
    },
  },
  "@media (min-width: 992px)": {
    maxWidth: "960px",
    mainRaised: {
      margin: "-60px 30px 0px",
    },
  },
  "@media (min-width: 1200px)": {
    maxWidth: "1140px",
    mainRaised: {
      margin: "-60px 30px 0px",
    },
  },
  mainRaised: {
    //margin: "-60px 0px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  link: {
    textDecoration: "none",
  },
  textCenter: {
    textAlign: "center",
  },
};

export default componentsStyle;
