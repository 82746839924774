/*eslint-disable */
import React, { useState } from "react";

import { API } from "../../assets/js/API";

import Footer from "components/Footer/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import CustomInput from "../CustomInput/CustomInput.js";
import MapBox from "../MapBox/MapBox.js";
import Button from "../Buttons/Button.js";
import Snackbar from "../Snackbar/Snackbar.js";

import styles from "assets/jss/views/ViewSections/bottomSectionStyle.js";
import { makeStyles } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
//import { API } from "assets/js/API.js";

const useStyles = makeStyles(styles);

export default function BottomSection() {
  const classes = useStyles();
  const [error, setError] = useState({ sent: false });
  const [success, setSuccess] = useState(false);
  const [dataToSend, setDataToSend] = useState({
    first_name: "",
    last_name: "",
    company: "",
    email: "",
    message: "",
  });
  const { first_name, last_name, company, email, message } = dataToSend;
  const sendMessage = (event) => {
    console.log(dataToSend);
    API.sendMessage(dataToSend).then((response) => {
      if (!response.id) {
        setError({ sent: true, ...response });
      } else {
        setSuccess(true);
      }

      console.log(response);
    });
    event.preventDefault();
  };
  const clearForm = () => {
    setDataToSend({
      first_name: "",
      last_name: "",
      company: "",
      email: "",
      message: "",
    });
    setSuccess(false);
    setError({ sent: false });
  };
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <h2 className={classes.title}>Our location</h2>
              <GridItem xs={12} sm={12} style={{ minHeight: 300 }}>
                <MapBox />
              </GridItem>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <h2 className={classes.title}>Send us a message</h2>
              {success ? (
                <Snackbar
                  onClose={clearForm}
                  message={
                    <span>
                      <b>SUCCESS: </b> Your message was sent
                    </span>
                  }
                  close
                  color="success"
                  icon={Check}
                />
              ) : null}

              <form onSubmit={sendMessage}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputProps={{
                        name: "first_name",
                        value: first_name,
                        onChange: (event) =>
                          setDataToSend({
                            ...dataToSend,
                            first_name: event.target.value.slice(0, 32),
                          }),
                      }}
                      error={error?.first_name ? true : false}
                      success={!error?.first_name && error.sent}
                      helpText={error?.first_name && error?.first_name[0]}
                      labelText="First Name"
                      id="first_name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputProps={{
                        name: "last_name",
                        value: last_name,
                        onChange: (event) =>
                          setDataToSend({
                            ...dataToSend,
                            last_name: event.target.value.slice(0, 32),
                          }),
                      }}
                      error={error?.last_name ? true : false}
                      success={!error?.last_name && error.sent}
                      helpText={error?.last_name && error?.last_name[0]}
                      labelText="Last Name"
                      id="last_name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputProps={{
                        name: "company",
                        value: company,
                        onChange: (event) =>
                          setDataToSend({
                            ...dataToSend,
                            company: event.target.value.slice(0, 32),
                          }),
                      }}
                      error={error?.company ? true : false}
                      success={!error?.company && error.sent}
                      helpText={error?.company && error?.company[0]}
                      labelText="Company"
                      id="company"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputProps={{
                        name: "email",
                        type: "email",
                        value: email,
                        onChange: (event) =>
                          setDataToSend({
                            ...dataToSend,
                            email: event.target.value,
                          }),
                      }}
                      error={error?.email ? true : false}
                      success={!error?.email && error.sent}
                      helpText={error?.email && error?.email[0]}
                      labelText="Your Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      error={error?.message ? true : false}
                      success={!error?.message && error.sent}
                      helpText={error?.message && error?.message[0]}
                      labelText="Your Message"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textArea,
                      }}
                      inputProps={{
                        multiline: true,

                        rows: 5,
                        value: message,
                        onChange: (event) =>
                          setDataToSend({
                            ...dataToSend,
                            message: event.target.value.slice(0, 200),
                          }),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <Button color={"primary"} type="submit">
                      Send Message
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
