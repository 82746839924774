import React from "react";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

import styles from "../../assets/jss/views/viewStyles.js";

import DataServerImage from "assets/img/DataServer.jpg";
import WhatWeDoSection from "./Sections/WhatWeDoSection.js";
import ClientsSection from "./Sections/ClientsSection.js";
import Layout from "components/Layout.js";

const useStyles = makeStyles(styles);

export default function Home() {
  const classes = useStyles();
  document.title = "Solutions General LTD";
  return (
    <Layout>
      <Parallax filter image={DataServerImage}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Solutions General Ltd</h1>
                <h3 className={classes.subtitle}>
                  Electrical, Communications and Security Engineering Solutions
                  Provider
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <WhatWeDoSection />
          <ClientsSection />
        </div>
      </div>
    </Layout>
  );
}
