import React, { Fragment } from "react";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

import styles from "../../assets/jss/views/viewStyles.js";

import Sunset from "assets/img/Sunset.jpg";

const useStyles = makeStyles(styles);

export default function Error() {
  const classes = useStyles();
  return (
    <Fragment>
      <Header
        brand="Solutions General"
        href="/"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />

      <Parallax style={{ height: "100vh" }} image={Sunset}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classNames(classes.brand, classes.textCenter)}>
                <h1 className={classes.title}>404</h1>
                <h3 className={classes.textCenter}>Page Not Found</h3>
                <h5>
                  We{"'"}re either developing this page, or it does not exist :(
                </h5>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
    </Fragment>
  );
}
