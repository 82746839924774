import React, { useEffect, useRef } from "react";
import "./NewBlock.css";
import $ from "jquery";

import PropTypes from "prop-types";

const NewBlock = (props) => {
  const { children } = props;
  const BlockRef = useRef(null);

  useEffect(() => {
    //const container = $(BlockRef.current);
    $(window).on("load", function () {
      const container = $(BlockRef.current);
      const animation = "smoothscroll";
      var sliderWidth = 0;
      //var animationWidth = 0;
      var sliderHeight = $(">div>div:first-of-type", container).outerHeight(
        false
      );

      /*$(">div>div", container).each(function () {
        animationWidth += $(this).outerWidth(false);
      });*/

      // detect number of visible slides
      var slidesVisible =
        $(container).width() /
        $(">div>div:first-of-type", container).outerWidth(false);
      slidesVisible = Math.ceil(slidesVisible);
      // count slides to determine animation speed
      var slidesNumber = $(">div>div", container).length;
      var speed = slidesNumber * 2;

      // append the tail
      $(">div>div", container)
        .slice(0, slidesVisible)
        .clone()
        .appendTo($(">div", container));

      // Detect the slider width with appended tail
      $(">div>div", container).each(function () {
        sliderWidth += $(this).outerWidth(false);
      });

      // set slider dimensions
      $(">div", container).css({ width: sliderWidth, height: sliderHeight });

      // Insert styles to html
      $(
        "<style>@keyframes " +
          animation +
          " { 0% { margin-left: 0px; }" +
          `100% {margin-left: -${sliderWidth}px; }}` +
          `/*100% { margin-left: -" ${0}px;}}*/` +
          ".block>div>div:first-of-type {" +
          `-webkit-animation: ${animation} ${speed}s linear infinite;` +
          `-moz-animation: ${animation} ${speed}s linear infinite;` +
          `-ms-animation: ${animation} ${speed}s linear infinite;` +
          `-o-animation: ${animation} ${speed}s linear infinite;` +
          `animation: ${animation} ${speed}s linear infinite;` +
          " }</style>"
      ).appendTo("head");

      // restart the animation (e.g. for safari & ie)
      var cl = $(container).attr("class");
      $(container)
        .removeClass(cl)
        .animate({ nothing: null }, 1, function () {
          $(this).addClass(cl);
        });
    });

    return () => $(BlockRef.current).remove();
  }, []);
  return (
    <div
      className={"block"}
      ref={BlockRef}
      style={{ margin: 0, width: "100%" }}
    >
      <div className="animation">{children}</div>
    </div>
  );
};
NewBlock.propTypes = {
  children: PropTypes.node.isRequired,
};
export default NewBlock;
//fallback
/*
    const BlockElement = $(BlockRef.current);
    var sliderWidth = 0;
    var animationWidth = 0;

    var sliderHeight = $(">div>div:first-of-type", BlockElement).outerHeight(
      false
    );
    $(">div>div", BlockElement).each(function () {
      animationWidth += $(this).outerWidth(false);
    });
    var slidesVisible =
      $(BlockElement).outerWidth(false) /
      $(">div>div:first-of-type", BlockElement).outerWidth(false);
    slidesVisible = Math.ceil(slidesVisible);
    var slidesNumber = $(">div>div", BlockElement).length;
    var speed = slidesNumber * 2;
    $(">div>div", BlockElement)
      .slice(0, slidesVisible)
      .clone()
      .appendTo($(">div", BlockElement));

    $(">div>div", BlockElement).each(function () {
      sliderWidth += $(this).outerWidth(false);
    });

    // Insert styles to html
    const animation = "smoothscroll";
    $(
      "<style type='text/css'>" +
        ".block>div { width: " +
        sliderWidth +
        "px; height: " +
        sliderHeight +
        "px; }" +
        "@keyframes " +
        animation +
        " { 0% { margin-left: 0px; } 100% { margin-left: -" +
        animationWidth +
        "px; } } " +
        ".block>div>div:first-of-type" +
        " { -webkit-animation: " +
        animation +
        " " +
        speed +
        "s linear infinite; -moz-animation: " +
        animation +
        " " +
        speed +
        "s linear infinite; -ms-animation: " +
        animation +
        " " +
        speed +
        "s linear infinite; -o-animation: " +
        animation +
        " " +
        speed +
        "s linear infinite; animation: " +
        animation +
        " " +
        speed +
        "s linear infinite; }</style>"
    ).appendTo("head");
    return () => {
      BlockElement.remove();
    };*/
