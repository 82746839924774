import React from "react";
import ReactDOM from "react-dom";
//import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

import Home from "views/Home/Home.js";
import Projects from "views/Projects/Projects.js";
import ContactUs from "views/ContactUs/ContactUs.js";
import AboutUs from "views/AboutUs/AboutUs.js";
import Licenses from "views/Licenses/Licenses.js";
import Error from "views/Error/Error.js";

//var history = createBrowserHistory();
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/licenses" component={Licenses} />
      <Route component={Error} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
