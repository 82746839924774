import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ReactMarkdown from "react-markdown";

// @material-ui/icons
import InfoIcon from "@material-ui/icons/Info";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Modal from "../../../components/Modal/Modal";
import NavPills from "components/NavPills/NavPills.js";

import styles from "../../../assets/jss/views/ViewSections/projectsSectionStyle.js";
import { API } from "../../../assets/js/API";
/*const projects = [
  {
    id: 1,
    title: "Project 1",
    project_image: "http://fpoimg.com/800x800?text=First",
    short_description: "Project 1 description",
    description:
      "You can write here a general overview about the projects. Written in markdown",
    details:
      "Details about the project go here, you can include '-' for bullets. E.g \n\nTechnical Aspects:\n\n- Installed CAT 6 cables\n\n- Project took 10 days",
    images: [
      "http://fpoimg.com/500x300?text=Preview&bg_color=00001e",
      "http://fpoimg.com/500x250?text=Preview&bg_color=eb001e",
      "http://fpoimg.com/500x300?text=Preview&bg_color=ebff1e",
      "http://fpoimg.com/500x330?text=Preview&bg_color=eb00ff",
      "http://fpoimg.com/500x630?text=Preview&bg_color=eb00ff",
    ],
  },

  {
    id: 2,
    title: "Project 2",
    project_image: "http://fpoimg.com/400x400?text=Second",
    short_description: "Project 2 description",
    description:
      "You can write here a general overview about the projects. Written in markdown",
    details:
      "Details about the project go here, you can include '-' for bullets. E.g \n\nTechnical Aspects:\n\n- Installed CAT 6 cables\n\n- Project took 10 days",
    images: [
      "http://fpoimg.com/500x300?text=Preview&bg_color=00001e",
      "http://fpoimg.com/500x250?text=Preview&bg_color=eb001e",
      "http://fpoimg.com/500x300?text=Preview&bg_color=ebff1e",
      "http://fpoimg.com/500x330?text=Preview&bg_color=eb00ff",
      "http://fpoimg.com/500x630?text=Preview&bg_color=eb00ff",
    ],
  },

  {
    id: 3,
    title: "Project 3",
    project_image: "http://fpoimg.com/200x200?text=Third",
    short_description: "Project 3 description",
    description:
      "You can write here a general overview about the projects. Written in markdown",
    details:
      "Details about the project go here, you can include '-' for bullets. E.g \n\nTechnical Aspects:\n\n- Installed CAT 6 cables\n\n- Project took 10 days",
    images: [
      "http://fpoimg.com/500x300?text=Preview&bg_color=00001e",
      "http://fpoimg.com/500x250?text=Preview&bg_color=eb001e",
      "http://fpoimg.com/500x300?text=Preview&bg_color=ebff1e",
      "http://fpoimg.com/500x330?text=Preview&bg_color=eb00ff",
      "http://fpoimg.com/500x630?text=Preview&bg_color=eb00ff",
    ],
  },
  {
    id: 4,
    title: "Project 4",
    project_image: "http://fpoimg.com/400x400?text=Fourth",
    short_description: "Project 4 description",
    description:
      "You can write here a general overview about the projects. Written in markdown",
    details:
      "Details about the project go here, you can include '-' for bullets. E.g \n\nTechnical Aspects:\n\n- Installed CAT 6 cables\n\n- Project took 10 days",
    images: [
      "http://fpoimg.com/500x300?text=Preview&bg_color=00001e",
      "http://fpoimg.com/500x250?text=Preview&bg_color=eb001e",
      "http://fpoimg.com/500x300?text=Preview&bg_color=ebff1e",
      "http://fpoimg.com/500x330?text=Preview&bg_color=eb00ff",
      "http://fpoimg.com/500x630?text=Preview&bg_color=eb00ff",
    ],
  },
];*/

const useStyles = makeStyles(styles);
export default function ProjectsSection() {
  const classes = useStyles();
  const [currentOpen, setCurrentOpen] = useState(-1);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    API.getProjects()
      .then((response) => {
        setProjects(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const imageClasses = classNames(
    classes.imgFluid,
    classes.imgRaised,
    classes.imgRounded,
    classes.imgCardTop
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  if (loading) {
    return (
      <div className={classes.section}>
        <h2 className={classes.title}>Projects</h2>
        <div>
          <GridContainer>
            <GridItem
              xs={12}
              md={12}
              style={{ height: "100vh", marginLeft: "50%", marginRight: "50%" }}
            >
              <CircularProgress color="primary" />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Projects</h2>
      <div>
        <GridContainer className={classes.staggered}>
          {projects.map((project) => (
            <GridItem xs={12} sm={12} md={12} key={project?.id}>
              <Card>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4} className={classes.itemGrid}>
                    <img
                      src={project?.cover_image}
                      alt="Project Image"
                      className={imageClasses}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    <CardBody className={classes.cardBodyText}>
                      <h4 className={classes.cardTitle}>
                        {project?.title}
                        <br />
                        <small className={classes.smallTitle}>
                          {project?.short_description}
                        </small>
                      </h4>
                      <div
                        className={classes.description}
                        style={{ maxHeight: "100px", overflow: "hidden" }}
                      >
                        <ReactMarkdown allowDangerousHtml>
                          {project?.description}
                        </ReactMarkdown>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Button
                        variant="outlined"
                        style={{ color: "#9c27b0" }}
                        className={classes.margin5}
                        onClick={() => setCurrentOpen(project?.id)}
                      >
                        More
                      </Button>
                      <Modal
                        isOpen={currentOpen == project?.id}
                        closeHandle={setCurrentOpen}
                        title={project?.title}
                      >
                        <NavPills
                          color="primary"
                          tabs={[
                            {
                              tabButton: "Details",
                              tabIcon: InfoIcon,
                              tabContent: (
                                <span>
                                  <ReactMarkdown>
                                    {project?.details}
                                  </ReactMarkdown>
                                </span>
                              ),
                            },
                            {
                              tabButton: "Images",
                              tabIcon: PhotoLibraryIcon,
                              tabContent: (
                                <GridContainer justify="center">
                                  <GridItem xs={12} sm={12} md={6}>
                                    {project?.images
                                      .filter((image, index) => index % 2 === 0)
                                      .map((image) => {
                                        return (
                                          <img
                                            key={image?.id}
                                            alt={image?.text}
                                            src={image?.image}
                                            className={navImageClasses}
                                          />
                                        );
                                      })}
                                    {/*<img
                                      alt="..."
                                      src={project.images[0]}
                                      className={navImageClasses}
                                    />
                                    <img
                                      alt="..."
                                      src={project.images[1]}
                                      className={navImageClasses}
                                    />
                                    <img
                                      alt="..."
                                      src={project.images[2]}
                                      className={navImageClasses}
                                    />*/}
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={6}>
                                    {project?.images
                                      .filter((image, index) => index % 2 === 1)
                                      .map((image) => {
                                        return (
                                          <img
                                            key={image?.id}
                                            alt={image?.text}
                                            src={image?.image}
                                            className={navImageClasses}
                                          />
                                        );
                                      })}
                                    {/*<img
                                      alt="..."
                                      src={project.images[3]}
                                      className={navImageClasses}
                                    />
                                    <img
                                      alt="..."
                                      src={project.images[4]}
                                      className={navImageClasses}
                                    />*/}
                                  </GridItem>
                                </GridContainer>
                              ),
                            },
                          ]}
                        />
                      </Modal>
                    </CardFooter>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  );
}
/**<GridContainer className={classes.staggered}>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <GridContainer>

                <GridItem xs={12} sm={12} md={4} className={classes.itemGrid}>
                  <img
                    src={"http://fpoimg.com/800x800?text=First"}
                    alt="..."
                    className={imageClasses}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8} className={""}>
                  <CardBody className={classes.cardBodyText}>
                    <h4 className={classes.cardTitle}>
                      Project 1
                      <br />
                      <small className={classes.smallTitle}>
                        Project 1 description
                      </small>
                    </h4>
                    <div className={classes.description}>
                      <ReactMarkdown allowDangerousHtml>
                        {`You can write here details about one of your projects.
                        You can give more details about what was done. Feel free
                        to add &lt;li&gt; tags to make lists one can also use
                        <code>markdown</code>`}
                      </ReactMarkdown>
                    </div>
                  </CardBody>
                  <CardFooter className={""}>
                    <Button
                      variant="outlined"
                      style={{ color: "#9c27b0" }}
                      className={classes.margin5}
                    >
                      More
                    </Button>
                  </CardFooter>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} className={classes.itemGrid}>
                  <img
                    src={"http://fpoimg.com/400x400?text=Second"}
                    alt="..."
                    className={imageClasses}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8} className={""}>
                  <CardBody className={classes.cardBodyText}>
                    <h4 className={classes.cardTitle}>
                      Project 2
                      <br />
                      <small className={classes.smallTitle}>
                        Project 2 description
                      </small>
                    </h4>
                    <p className={classes.description}>
                      You can write here details about one of your projects. You
                      can give more details about what was done. Feel free to
                      add &lt;li&gt; tags to make lists one can also use{" "}
                      <code>markdown</code>
                    </p>
                  </CardBody>

                  <CardFooter className={""}>
                    <Button
                      variant="outlined"
                      style={{ color: "#9c27b0" }}
                      className={classes.margin5}
                    >
                      More
                    </Button>
                  </CardFooter>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} className={classes.itemGrid}>
                  <img
                    src={"http://fpoimg.com/200x200?text=Third"}
                    alt="..."
                    className={imageClasses}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8} className={""}>
                  <CardBody className={classes.cardBodyText}>
                    <h4 className={classes.cardTitle}>
                      Project 3
                      <br />
                      <small className={classes.smallTitle}>
                        Project 3 description
                      </small>
                    </h4>
                    <p className={classes.description}>
                      You can write here details about one of your projects. You
                      can give more details about what was done. Feel free to
                      add &lt;li&gt; tags to make lists one can also use{" "}
                      <code>markdown</code>
                    </p>
                  </CardBody>

                  <CardFooter className={""}>
                    <Button
                      variant="outlined"
                      style={{ color: "#9c27b0" }}
                      className={classes.margin5}
                    >
                      More
                    </Button>
                  </CardFooter>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} className={classes.itemGrid}>
                  <img
                    src={"http://fpoimg.com/400x400?text=Fourth"}
                    alt="..."
                    className={imageClasses}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                  <CardBody className={classes.cardBodyText}>
                    <h4 className={classes.cardTitle}>
                      Project 4
                      <br />
                      <small className={classes.smallTitle}>
                        Project 4 description
                      </small>
                    </h4>
                    <p className={classes.description}>
                      You can write here details about one of your projects. You
                      can give more details about what was done. Feel free to
                      add &lt;li&gt; tags to make lists one can also use{" "}
                      <code>markdown</code>
                    </p>
                  </CardBody>

                  <CardFooter className={""}>
                    <Button
                      variant="outlined"
                      style={{ color: "#9c27b0" }}
                      className={classes.margin5}
                    >
                      More
                    </Button>
                  </CardFooter>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer> */
