import React from "react";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Parallax from "../../components/Parallax/Parallax.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

import styles from "../../assets/jss/views/viewStyles.js";

import PowerLines from "assets/img/PowerLines.jpg";
import Layout from "components/Layout.js";

const useStyles = makeStyles(styles);

export default function AboutUs() {
  const classes = useStyles();
  document.title = "About Us";
  return (
    <Layout>
      <Parallax filter small image={PowerLines}>
        <div className={classNames(classes.container)}>
          <GridContainer>
            <GridItem>
              <div className={classNames(classes.brand, classes.textCenter)}>
                <h1 className={classes.title}>About Us</h1>
                <h3 className={classes.textCenter}>
                  The ones behind making Solutions General a great company
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div style={{ padding: "50px 0", textAlign: "center" }}>
            We are a group of Professional Engineers dedicated to providing
            solutions to solve the problem to the satisfaction of the customer
            <GridContainer>
              <GridItem xs={12} md={6}>
                <h2>Our Mission</h2>
                <p>
                  Providing clean and quality services honestly and diligently
                  before all men for the glory of God
                </p>
              </GridItem>
              <GridItem xs={12} md={6}>
                <h2>Our Vision</h2>
                <p>
                  Providing clean and quality services honestly and diligently
                  before all men for the glory of God
                </p>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
}
