import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import NewBlock from "../../../components/NewBlock/NewBlock.js";
import Image from "../../../components/Image/Image.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/views/ViewSections/clientsSectionStyle.js";
import { API } from "../../../assets/js/API";

const useStyles = makeStyles(styles);
/*
const client_images = [
  "http://fpoimg.com/200x200?text=First",
  "http://fpoimg.com/200x200?text=Second",
  "http://fpoimg.com/200x200?text=Third",
  "http://fpoimg.com/200x200?text=Fourth",
  "http://fpoimg.com/500x200?text=Fifth",
  "http://fpoimg.com/200x500?text=Sixth",
  "http://fpoimg.com/200x200?text=Seventh",
  "http://fpoimg.com/100x200?text=Eighth",
  "http://fpoimg.com/200x200?text=Nineth",
  "http://fpoimg.com/200x200?text=Tenth",
];*/

export default function ClientsSection() {
  const classes = useStyles();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    API.getClients()
      .then((data) => {
        setClients(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  if (loading) {
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.title}>Our Clients</h2>
            <CircularProgress
              style={{ marginLeft: "50%", marginRight: "50%" }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title}>Our Clients</h2>
        </GridItem>
      </GridContainer>
      <NewBlock>
        {clients.map(({ client_image, company }) => {
          return <Image key={company} image={client_image} alt={company} />;
        })}
      </NewBlock>
    </div>
  );
}
